import React, { useCallback, useEffect } from "react";
import { Modal, Button, Alert } from "reactstrap";
import { API, BACKEND_HOST } from "../../api";
import axios from "axios";
import { useInfiniteQuery } from "react-query";
import moment from "moment";
import { getAccessToken } from "../../api/auth";
import { SITE_ID } from "../../assets/meta/meta";

const CouponReservationSelectModal = (props) => {
  const [holidays, setHolidays] = React.useState([]);

  const today = moment(moment().format("YYYY-MM-DD"));
  const boundType = "reservation";


  const togModal = () => {
    props.setVisible(!props.visible);
  };
  const useConfirm = (message = null, onConfirm, onCancel) => {
    if (!onConfirm || typeof onConfirm !== "function") {
      return;
    }
    if (onCancel && typeof onCancel !== "function") {
      return;
    }

    const confirmAction = () => {
      if (window.confirm(message)) {
        onConfirm();
      } else {
        onCancel();
      }
    };

    return confirmAction;
  };

  const accessToken = getAccessToken();

  useEffect(() => {
    const this_year = today.format("YYYY");
    API.get(`/package/holiday/${this_year}/`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.data)
      .then((_data) => {
        console.log("holiday", _data);
        setHolidays(_data.result);
      })
      .catch((error) => console.log(error));
  }, [accessToken]);

  const fetchData = useCallback(
    async ({ pageParam = 1 }) => {
      const response = await API.get(`/coupon/user/?page=${pageParam}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      });
      const data = response.data;
      //   console.log("orderdata", data);
      return { ...data, results: data.results };
    },
    [accessToken]
  );

  const { status, data, error, isSuccess, isFetching, isFetchingNextPage, isFetchingPreviousPage, fetchNextPage, fetchPreviousPage, hasNextPage, hasPreviousPage, refetch } = useInfiniteQuery(
    ["UserCouponList"],
    async ({ pageParam = 1 }) => {
      // console.log("pageParam", pageParam);
      const data = await fetchData({ pageParam });
      //   console.log("data", data);
      return data;
    },
    {
      // getNextPageParam: (lastPage) => (lastPage.hasMore ? lastPage.page + 1 : undefined),
      getNextPageParam: (lastPage) => {
        if (lastPage && lastPage.next) {
          const match = lastPage.next.match(/page=(\d+)/);
          if (match) {
            return match[1];
          }
        }
        return undefined;
      },
      // getPreviousPageParam: (firstPage) => undefined,
    }
  );

  // useEffect(() => {
  //   console.log("coupon", props.tickets);
  // }, [props.tickets]);

  return (
    <React.Fragment>
      <Modal toggle={togModal} isOpen={props.visible} modalClassName="fadeInUp" centered>
        <div className="mx-3 my-3 pt-4" style={{
          maxHeight: "70vh",
          height: "70vh",
          overflowY: "auto",
        }}>

          <ul className="list-group">
            {data &&
              isSuccess &&
              data.pages.map((page, i) =>
                page.results
                  .filter((coupons, j) => coupons.coupon.bound_type === "reservation" && coupons.state === "사용안함")
                  .map((item, j) => {
                    const target_date = moment(props.seats.reserve_date, "YYYY-MM-DD");

                    // const isSelected = props.selectCoupon.find((coupon) => coupon.coupon_id === item.id && coupon.spot_id === props.seats.spot_id);
                    const isBlocked = !!props.selectCoupon.find((coupon) => coupon.coupon_id === item.id && coupon.spot_id !== props.seats.spot_id);
                    const isExpired = today > moment(item.coupon.expire_date.slice(0, 10), "YYYY-MM-DD");
                    const isDateValid = moment(item.coupon.use_start_date) <= target_date && target_date <= moment(item.coupon.use_end_date);
                    const isUsedSite = item.coupon.sites.find((site) => site === SITE_ID);
                    const isUsed = item.state !== "사용안함";
                    const isPriceValid = Number(item.coupon.min_purchase) <= Number(props.seats.price);
                    const isPackageIncluded = item.coupon.packages.find((item) => item === props.seats.product_id);

                    let isDateAvailable = true
                    if (item.coupon.use_mon === false && target_date.day() === 1) isDateAvailable = false
                    if (item.coupon.use_tue === false && target_date.day() === 2) isDateAvailable = false
                    if (item.coupon.use_wed === false && target_date.day() === 3) isDateAvailable = false
                    if (item.coupon.use_thu === false && target_date.day() === 4) isDateAvailable = false
                    if (item.coupon.use_fri === false && target_date.day() === 5) isDateAvailable = false
                    if (item.coupon.use_sat === false && target_date.day() === 6) isDateAvailable = false
                    if (item.coupon.use_sun === false && target_date.day() === 0) isDateAvailable = false
                    if (item.coupon.use_holiday === false && holidays && holidays.find((holiday) => holiday === props.seats.reserve_date)) isDateAvailable = false

                    const disabled = isExpired || isUsed || item.coupon.bound_type !== boundType || isBlocked || !isDateValid || !isUsedSite || !isPriceValid || !isDateAvailable || !isPackageIncluded;

                    const hidden = isExpired || isUsed || item.coupon.bound_type !== boundType
                    if (hidden) return null;

                    // console.log("id", item.id);
                    // console.log("target_date", props.seats.reserve_date);
                    // console.log("isExpired", isExpired);
                    // console.log("isUsed", isUsed);
                    // console.log("isBlocked", isBlocked);
                    // console.log("isDateValid", isDateValid);
                    // console.log("isUsedSite", isUsedSite);
                    // console.log("isPriceValid", isPriceValid);
                    // console.log("isPackageIncluded", isPackageIncluded);
                    // console.log("isDateAvailable", isDateAvailable);

                    return (
                      <li key={`${i}_${j}`} className="list-group-item" style={{ opacity: disabled ? 0.5 : 1, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <div style={{ marginTop: "15px", marginBottom: "15px" }}>
                          <p style={{ fontSize: "15px", fontFamily: "Gmarket-M", margin: 0, marginBottom: "5px", maxWidth: "95%" }}>{item.coupon.title}</p>
                          <p style={{ margin: 0, marginBottom: "5px" }}>만료기한 {item.coupon.expire_date}까지</p>
                          <p style={{ margin: 0, marginBottom: "5px" }}>
                            예약기간 {item.coupon.use_start_date} ~ {item.coupon.use_end_date}
                          </p>
                          <p style={{ fontSize: "15px", fontFamily: "Gmarket-M", color: "#ff5a5f", margin: 0, marginBottom: "5px" }}>
                            {item.coupon.discount_type === "price" ? item.coupon.discount_amount.toLocaleString() + "원 할인" : item.coupon.discount_rate + "% 할인"}
                          </p>
                          <p style={{ margin: 0 }}>구매금액 {item.coupon.min_purchase.toLocaleString()}원 이상 사용가능</p>
                          {item.coupon.discount_type === "percentage" ? <p>최대 {item.coupon.max_discount.toLocaleString()}원까지 할인</p> : null}
                        </div>

                        {disabled ? (
                          <p>사용할 수 없는 쿠폰입니다.</p>
                        ) : (
                          <input
                            type="radio"
                            name="coupon"
                            value={item.id}
                            disabled={disabled}
                            checked={
                              props.selectCoupon.length > 0
                                ? props.selectCoupon.find((coupons) => coupons.coupon_id === item.id && coupons.product_id === props.seats.product_id && coupons.spot_id === props.seats.spot_id)
                                : false
                            }
                            onChange={() => {
                              props.toggleReservationDiscountCouponSelection(
                                props.seats.product_id,
                                item.id,
                                props.seats.spot_id,
                                item.coupon.discount_amount,
                                item.coupon.discount_type,
                                item.coupon.min_purchase,
                                item.coupon.discount_rate,
                                item.coupon.max_discount
                              );
                            }}
                          />
                        )}
                      </li>
                    );
                  })
              )}
            
            {isFetching && <div>Loading...</div>}
            {isFetchingNextPage && <div>Loading more...</div>}
            {isSuccess && !data.pages[0].results.length && <Alert color="info">보유하신 쿠폰이 없습니다.</Alert>}
            {hasNextPage && (
              <Button
                color="light"
                onClick={() => {
                  fetchNextPage();
                }}
              >
                다음 불러오기
              </Button>
            )}
          </ul>
        </div>
        <div className="d-flex justify-content-center mx-1 mb-4 pb-2">
          <Button
            color="light ms-2 my-2"
            style={{ fontFamily: "Gmarket-M", backgroundColor: "#fff", borderColor: "#ff5a5f", color: "#ff5a5f" }}
            onClick={() => {
              const updatedSelectCoupon = props.selectCoupon.filter((coupon) => coupon.product_id === props.seats.product_id && coupon.spot_id !== props.seats.spot_id);
              // console.log("updatedSelectCoupon", props.seats);
              props.setSelectCoupon(updatedSelectCoupon);
              props.setVisible(!props.visible);
            }}
          >
            취소
          </Button>
          <Button
            color="light ms-2 my-2"
            style={{ fontFamily: "Gmarket-M", backgroundColor: "#ff5a5f", borderColor: "#ff5a5f", color: "#fff" }}
            onClick={() => {
              props.setVisible(!props.visible);
            }}
          >
            적용
          </Button>
        </div>
      </Modal>
    </React.Fragment>
  );
};
export default CouponReservationSelectModal;
