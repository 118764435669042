import React, { useEffect } from "react";
import { Modal, Button, Alert } from "reactstrap";
import { BACKEND_HOST } from "../../api";
import moment from "moment";
import axios from "axios";
import { getAccessToken } from "../../api/auth";

const ShoppingRefundModal = (props) => {
  const togModal = () => {
    props.setVisible(!props.visible);
  };
  const useConfirm = (message = null, onConfirm, onCancel) => {
    if (!onConfirm || typeof onConfirm !== "function") {
      return;
    }
    if (onCancel && typeof onCancel !== "function") {
      return;
    }

    const confirmAction = () => {
      if (window.confirm(message)) {
        onConfirm();
      } else {
        onCancel();
      }
    };

    return confirmAction;
  };

  return (
    <React.Fragment>
      <Modal
        toggle={togModal}
        isOpen={props.visible}
        modalClassName="fadeInUp"
        centered
      >
        <div className="mx-3 my-3 pt-4">
          {/* <h3>티켓 디테일</h3> */}
          <ul className="list-group">
            {/* <li className="list-group-item">환불상품 : {props.info.name}</li> */}
            <li className="list-group-item">
              상품명 :{" "}
              {props.info.shopping_products?.map((product, index) => (
                <>{product.name},</>
              ))}
            </li>

            {props.info.variation ? (
              <li className="list-group-item">
                상품옵션 : {props.info.variation.value}
              </li>
            ) : null}

            <li className="list-group-item">주문번호 : {props.info?.id}</li>
            <li className="list-group-item">
              결제일 :{" "}
              {moment(props.info.created_at).format("YYYY-MM-DD HH:mm")}
            </li>
            <li className="list-group-item">
              환불금액 : {props.info.amount?.toLocaleString()}원
            </li>
          </ul>
          <div
            style={{
              marginTop: "10px",
              paddingLeft: "5px",
              textAlign: "center",
              fontFamily: "Gmarket-M",
            }}
          >
            배송이 시작된 후 주문취소는 고객센터로 문의 부탁드립니다.
            <br /> 
          </div>
        </div>
        <div className="d-flex justify-content-center mx-1 mb-4">
          <Button
            color="light ms-2 my-2"
            style={{
              fontFamily: "Gmarket-M",
              backgroundColor: "#fff",
              borderColor: "#ff5a5f",
              color: "#ff5a5f",
            }}
            onClick={() => {
              props.setInfo({});
              props.setVisible(!props.visible);
            }}
          >
            닫기
          </Button>
          <Button
            style={{
              fontFamily: "Gmarket-M",
              backgroundColor: "#ff5a5f",
              borderColor: "#ff5a5f",
              color: "#fff",
            }}
            color="danger ms-2 my-2"
            onClick={useConfirm(
              `정말로 취소하시겠습니까?\n 주문이 모두 환불 됩니다.`,
              () => {
                props.goCancel(props.info.id);
              },
              () => {}
            )}
          >
            주문취소
          </Button>
        </div>
      </Modal>
    </React.Fragment>
  );
};
export default ShoppingRefundModal;
