import React, { useEffect } from "react";
import {
  Col, Container, Row, Button,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { SITE_ID } from "../../../assets/meta/meta";
import { SloganModal, CarouselModal } from "../../../Components/Common/ManagerModal";
import { API } from "../../../api";
import KakaoMap from "../../CampMap/KaKaoMap";
import AmCarousel from "../../../Components/AmCarousel";



const Home = (props) => {
  const navigate = useNavigate();

  const [site, setSite] = React.useState({});
  const [sloganText, setSloganText] = React.useState("");
  const [slogan, setSlogan] = React.useState(true);

  const [carousel, setCarousel] = React.useState([]);
  const [carouselModalNumber, setCarouselModalNumber] = React.useState(0);
  const [targetCarousel, setTargetCarousel] = React.useState(-1);

  const [sloganEditModal, setSloganEditModal] = React.useState(false);
  const [carouselEditModal, setCarouselEditModal] = React.useState(false);
  const [mainNoticeCarousel, setMainNoticeCarousel] = React.useState([]);
  const [siteName, setSiteName] = React.useState("");

  // useEffect(() => {
  //   console.log("carousel", carousel);
  // }, [carousel]);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const siteResponse = await API.get(`/sites/camps/${SITE_ID}/`);
        const siteData = siteResponse.data;
        setSite(siteData);
        setSiteName(siteData.name);
        setSloganText(siteData.slogan);

        const carouselResponse = await API.get(`/sites/carousel/?site=${SITE_ID}`);
        const carouselData = carouselResponse.data;
        setCarousel(carouselData);

        const mainNoticeCarouselResponse = await API.get(`/sites/carousel/?target_app=true`);
        const mainNoticeCarouselData = mainNoticeCarouselResponse.data;
        setMainNoticeCarousel(mainNoticeCarouselData);
      } catch (error) {
        console.error(error);
      }
    };

    // 함수를 호출하세요
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <section className="mt-4" id="hero">
        <Container>
          <Row className="justify-content-center">
            <Col lg={12} sm={12}>
              
              {/* {!!props.permission && props.permission.permission > 0 ? (
                <Button className="btn btn-soft-secondary mb-2 position-absolute start-0" onClick={() => setSloganEditModal(!sloganEditModal)} style={{ zIndex: "5" }}>
                  슬로건 추가 및 수정하기
                </Button>
              ) : // </div>
                null}
              {sloganText && sloganText.length > 0 ? (
                <div className="text-center">
                  {sloganText.split(";").map((line, idx) => (
                    <h2 key={idx} className="mb-2 fs-4"
                      style={{ fontFamily: "Jalnan", color: "#555" }}>
                      {line}
                    </h2>
                  ))}
                </div>
              ) : null} */}

              {carousel.length === 0 ? (
                <div className="mb-5" style={{ marginTop: "70px" }}>
                  <p className="mb-4" style={{ fontFamily: "Gmarket-M", fontSize: "16px", textAlign: "center", color: "#1d1d1d" }}>
                    준비중 입니다.
                  </p>
                </div>
              ) : null}

              {!!props.permission && props.permission.permission > 0 ? (
                /* carouselQuantity state 초기값은 1로 설정되어 있음  */
                <>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", marginBottom: "20px", marginTop: "0" }}>
                    <Button
                      className="btn btn-soft-primary"
                      style={{ fontFamily: "Gmarket-M", backgroundColor: "#f6475f1a", boxShadow: "none", border: "none", fontSize: "13px", color: "#f6475f", marginRight: "10px" }}
                      onClick={() => {
                        setCarouselEditModal(!carouselEditModal);
                        setCarouselModalNumber(-1);
                      }}
                    >
                      베너 그룹 추가
                    </Button>
                    <Button
                      style={{ fontFamily: "Gmarket-M", backgroundColor: "#f6475f1a", boxShadow: "none", border: "none", fontSize: "13px", color: "#f6475f", marginRight: "10px" }}
                      className="btn btn-soft-primary"
                      onClick={() => {
                        setCarouselEditModal(!carouselEditModal);
                        setCarouselModalNumber(-2);
                      }}
                    >
                      베너 그룹 순서 수정
                    </Button>
                  </div>
                  {carousel.map((item, idx) => {
                    return (
                      <div key={idx} className=" demo-carousel mb-4">
                        <Button
                          className="btn btn-soft-primary mb-3 position-absolute top-10 start-0"
                          style={{ zIndex: "2", fontFamily: "Gmarket-M", backgroundColor: "#f6475f", boxShadow: "none", border: "none", fontSize: "13px", color: "#fff", marginRight: "10px" }}
                          onClick={() => {
                            setCarouselEditModal(!carouselEditModal);
                            setCarouselModalNumber(idx);
                            setTargetCarousel(item.id);
                          }}
                        >
                          {idx + 1}번 베너 그룹 수정하기
                        </Button>

                        {carousel.length !== 0 ? (
                          <AmCarousel interval={4000} items={item.images} objectFit="cover" />
                        ) :
                          null}
                      </div>
                    );
                  })}
                </>
              ) : (
                <>
                  {carousel.map((item, idx) => {
                    return (
                      <div key={idx} className=" demo-carousel mb-2">
                        {carousel.length !== 0 ? <AmCarousel interval={4000} items={item.images} objectFit="cover" /> : null}
                      </div>
                    );
                  })}
                </>
              )}
            </Col>

            {/* 메인 공지 캐러셀 */}
            {site.is_managed ? (
              <>
                {mainNoticeCarousel.map((item, idx) => {
                  return (
                    <div key={idx} className="demo-carousel mb-5">
                      {mainNoticeCarousel.length !== 0 ?
                        <AmCarousel interval={3000} items={item.images} style={{ textAlign: "center" }} objectFit="cover" heightRatio={6/11} /> : null}
                    </div>
                  );
                })}
              </>
            ) : null}

            <div className="mt-5">
              <KakaoMap permission={props.permission} style={{ height: "40px" }} />
            </div>
          </Row>
        </Container>
      </section>

      {/* Updating Slogan Modal */}
      <SloganModal setVisible={setSloganEditModal} visible={sloganEditModal} origin={sloganText} slogan={slogan} setSlogan={setSlogan} site={site.id} navigate={navigate} />

      {/* Updating Carousel Modal */}
      <CarouselModal
        modalNumber={carouselModalNumber}
        carouselId={targetCarousel}
        setVisible={setCarouselEditModal}
        visible={carouselEditModal}
        site={site.id}
        navigate={navigate}
        carouselData={carousel}
      />
    </React.Fragment>
  );
};

export default Home;
